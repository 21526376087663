<template>
  <div class="edit-user">
    <q-page-container>
      <q-page>
        <UserForm :readonly="true" :is-edit="true" :edit-user.sync="user" />
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserForm from '@/components/new-user/UserForm'

const buildDepartments = (departments, isArray = true) => {
  const result = departments.map(department => {
    department.value = department.departmentId
    department.label = department.departmentName
    return department
  })

  if (isArray) return result

  return { ...result[0] }
}

export default {
  name: 'EditUser',
  components: {
    UserForm
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        primaryDepartmentId: '',
        departmentsId: [],
        rightDeleteDocument: false,
        rightDeleteModel: false,
        rightManageUsers: false,
        rightModifyModel: false,
        rightProjectDelete: false,
        userStatus: 'ACTIVE'
      }
    }
  },
  async created() {
    const { userId } = this.$route.params
    await this.fetchUserById({ userId })

    const {
      firstName,
      lastName,
      email,
      primaryDepartment,
      departments,
      userRights,
      userStatus
    } = this.getUserById

    this.user = {
      userId,
      firstName,
      lastName,
      email,
      primaryDepartmentId: buildDepartments([primaryDepartment], false),
      departmentsId: buildDepartments(departments),
      rightDeleteDocument: userRights.rightDeleteDocument,
      rightDeleteModel: userRights.rightDeleteModel,
      rightManageUsers: userRights.rightManageUsers,
      rightModifyModel: userRights.rightModifyModel,
      rightProjectDelete: userRights.rightProjectDelete,
      userStatus: userStatus
    }
  },
  computed: {
    ...mapGetters('users', [
      'getUserById',
      'getLoadingUserById',
      'getErrorUserById'
    ])
  },
  methods: {
    ...mapActions('users', ['fetchUserById']),
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  watch: {
    getErrorUserById: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
